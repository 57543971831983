import {Injectable} from '@angular/core';
import {StatItem} from '../../models/stats/stat-item';
import {LoggerService} from '../sp-logger/logger.service';
import {Subject, Subscription} from 'rxjs';
import {Project} from "../../models/project";
import {MetricType} from 'src/app/classes/reporting/metric-type';
import {WidgetType} from "../../classes/reporting/widget-type";
import {DataSource} from "../../classes/reporting/data-source";
import {ApiDashboardService} from '../sp-api/sp-api-dashboard/api-dashboard.service';
import {Dashboard} from '../../models/dashboard';
import {MediaGraphData, ReachData, SociodemoSummaryData} from "../../classes/data/data-provider";
import {GraphSorting} from '../../classes/reporting/graph-sorting';

export interface Scheme {
  links: Array<Link>;
  specs: GraphSpec[];
  version: number;
}

interface TargetingData {
  ageMin: number;
  ageMax: number;
  gender: string;
}

interface PersonaSelectData extends TargetingData{
  imgPath: string;
  audience_size: string;
}

export interface TopSegmentData extends TargetingData {
  imgPath: string;
  geoloc: string;
  flex: string;
  targeting: string;
}

interface GraphSpecExtra {
  customtxt?: string;
  b64data?: string;
  mapOptions?: any;
  summaryData?: SociodemoSummaryData;
  reachData?: ReachData;
  mediaData?: MediaGraphData;
  personaSelectData?: PersonaSelectData;
  mapDrilldownData?: any[];
}

export interface GraphSpec {
  id: string;
  name?: string;
  nameStyle?: string;
  description?: string;
  linkId: number;
  posX: number;
  posY: number;
  width: number;
  height: number;
  type?: WidgetType;
  sortBy?: GraphSorting;
  sortDir?: string;
  isTemplate?: boolean;
  metrics?: MetricType[];
  dataType?: DataSource;
  dataIdentifier?: string[];
  data?: StatItem[];
  templateData?: string;
  templateCategory?: string;
  savedState?: any;
  dragEnabled?: boolean ,
  resizeEnabled?: boolean,
  dataExtra?: GraphSpecExtra; // CUSTOM FIELD FOR PPTX
}

export interface Link {
  id: number;
  name: string;
  active: boolean;
}

export interface DashboardData {
  dataHandler: any;
  mapDataHandler: any;
  personaDataHandler: any;
  project: Project;
  name: string;
  version: number;
}

class IdUtils {
  private nextId: number = 0;
  private takenIds: number[] = [];

  public updateTakenIds(ids: number[]) {
    this.takenIds = ids;
    if (ids.length == 0) {
      this.nextId = 0;
    } else {
      this.nextId = ids.reduce((a, b) => a > b ? a : b) + 1;
    }
  }
  public reset() {
    this.takenIds = [];
    this.nextId = 0;
  }
  public getNextId(): number {
    let next = this.nextId++;
    while (this.takenIds.includes(next)) {
      next = this.nextId++;
    }

    this.takenIds.push(next);
    return next;
  }
}

export abstract class LinkUtils {
  private static idUtils: IdUtils = new IdUtils();

  public static updateTakenIds(ids: number[]) { this.idUtils.updateTakenIds(ids); }
  public static reset() { this.idUtils.reset(); }
  public static getNextLinkId(): number { return this.idUtils.getNextId(); }
}

export abstract class GraphSpecUtils {
  private static idUtils: IdUtils = new IdUtils();

  public static updateTakenIds(ids: number[]) { this.idUtils.updateTakenIds(ids); }
  public static reset() { this.idUtils.reset(); }
  public static getNextGraphId(): string { return "graph" + this.idUtils.getNextId(); }
}

@Injectable({
  providedIn: 'root'
})
export class ReportingManager {
  private _loadedDashboard?: Dashboard;
  private _graphSpecs: GraphSpec[] = [];
  private _links: Link[] = [];
  private _linkActive: Link;

  private _projectName: string = "Project";
  private _linkActiveListener = new Subject<Link>();
  private _linkCreateListener = new Subject<Link>();
  private _linkDeleteListener = new Subject<Link>();
  private _linkEditListener = new Subject<Link>();
  private _linkOverrideListener = new Subject<Link[]>();
  private _linkUpdatedListener = new Subject<void>();
  public static readonly CHART_SAVE_VERSION: number = 2;

  constructor(private logger: LoggerService, private apiDashboard: ApiDashboardService) { }

  /**
   * Next position after last title on the dashboard
   * If no position is found, undefined is returned, object with x and y attributes otherwise
   * @param newWidgetWidth
   * @param newWidgetHeight
   * @param maxWidth
   * @param maxHeight
   * @param startFromX
   * @param startFromY
   */
  public nextPosition(newWidgetWidth: number, newWidgetHeight: number, maxWidth?: number, maxHeight?: number, startFromX?: number, startFromY?: number): {x: number, y: number} {

    for (let y = startFromY || 0; y < (maxHeight || 9999); y++) {
      for (let x = startFromX || 0; x < (maxWidth || 9999); x++) {
        const collision = this.checkCollision(x, y, newWidgetWidth, newWidgetHeight);
        if (!collision && x + newWidgetWidth <= maxWidth) {
          return {x, y};
        }
      }
    }
    return undefined;
  }

  private checkCollision(x: number, y: number, width: number, height: number): boolean {
    return !!this.linkGraphSpecs.find(s => {
      return x < s.posX + s.width &&
        x + width > s.posX &&
        y < s.posY + s.height &&
        height + y > s.posY
    });
  }

  public addExistingSpec(spec: GraphSpec) {
    this._graphSpecs.push(spec);
  }

  public removeGraphSpec(id: string) {
    this._graphSpecs = this._graphSpecs.filter(g => g.id !== id);
  }

  public removeSpecsWithLink(link: Link) {
    this._graphSpecs = this._graphSpecs.filter(g => g.linkId != link.id);
  }

  public overrideScheme(newScheme: Scheme) {
    this._graphSpecs = newScheme.specs;
    this.loadLinks(newScheme.links);
    GraphSpecUtils.updateTakenIds(newScheme.specs.map(s => {
      const id = s.id.match(/\d+/g).join('');
      return parseInt(id, 10);
    }));
  }

  get linkActive(): Link { return this._linkActive; }

  public reset() {
    this._graphSpecs = [];
    this._links = [];
    this._linkOverrideListener.next([]);
    LinkUtils.reset();
    GraphSpecUtils.reset();
  }

  public projectSharedName(name){
    this._projectName = name;
    return this._projectName;
  }

  public loadName(){
      return this._projectName;
  }

  public async loadDashboard(id: number) {
    const dashboard = await this.apiDashboard.getOne(id);
    this._loadedDashboard = this.applyDashboardPatches(dashboard, ReportingManager.CHART_SAVE_VERSION);
    return dashboard;
  }

  public applyDashboardPatches(dashboard: Dashboard, version: number) {
    const schemeVersion = dashboard.scheme.version;
    if (schemeVersion) {
      if (schemeVersion < 2 && version >= 2) {
        this.logger.logInfo('Converting dashboard scheme from ver. ' + schemeVersion + ' to ver. 2')
        dashboard.scheme.specs.forEach(s => {
          s.dragEnabled = true;
          s.resizeEnabled = true;
        });
        dashboard.scheme.version = 2;
      }
    }

    return dashboard;
  }

  /**
   * Create link and return it if successful
   * @param name
   * @return link if success or undefined if id is already taken
   */
  public createLink(name: string): Link {
    const id = LinkUtils.getNextLinkId();
    const link: Link = {id, name: "New tab", active: false};
    this._links.push(link);
    this._linkCreateListener.next(link);
    this.linkSetActive(link);
    return link;
  }

  public updateLink(link: Link) {
    this._linkEditListener.next(link);
  }

  public loadLinks(links: Link[]) {
    this._linkOverrideListener.next([]);
    this._links = [];

    if (links.length > 0) {
      LinkUtils.updateTakenIds(links.map(l => l.id));
      this._links = links;
      links.forEach(l => this._linkCreateListener.next(l));
      const activelink = this._links.find(l => l.active);
      if (!activelink) this.linkSetActive(links[0]);
      else this._linkActive = activelink;
    }
  }

  public linkSetActive(link: Link) {

    if (this._linkActive == link) return;
    if (this._links.includes(link)) {
      this._links.filter(l => l.active).forEach(l => l.active = false);
      link.active = true;
      this._linkActive = link;
      this._linkActiveListener.next(link);
    } else {
      this.logger.logWarning("Set link active called on unknown link");
    }
  }

  public linkSetFirst() {
    this.linkSetActive(this._links[0]);
  }

  public linkUpdated() {
    this._linkUpdatedListener.next();
  }

  public linkActiveSub(obs: (link: Link) => void): Subscription { return this._linkActiveListener.subscribe(obs); }
  public linkCreatedSub(obs: (link: Link) => void): Subscription { return this._linkCreateListener.subscribe(obs); }
  public linkDeletedSub(obs: (link: Link) => void): Subscription { return this._linkDeleteListener.subscribe(obs); }
  public linkEditSub(obs: (link: Link) => void): Subscription { return this._linkEditListener.subscribe(obs); }
  public linkOverrideSub(obs: (links: Link[]) => void): Subscription { return this._linkOverrideListener.subscribe(obs); }
  public linkUpdatedSub(obs: (_: void) => void): Subscription { return this._linkUpdatedListener.subscribe(obs); }

  get graphSpecs(): GraphSpec[] { return this._graphSpecs; }
  get allLinks(): Link[] {return this._links}
  get linkGraphSpecs(): GraphSpec[] { return this.graphSpecs.filter(g => g.linkId == this.linkActive.id); }

  get blueprintScheme(): Scheme {
    const specs = this._graphSpecs.map(c => {
      const clone = {...c};
      clone.data = undefined;
      clone.dataExtra = undefined;
      return clone;
    });

    return {links: this._links, specs, version: ReportingManager.CHART_SAVE_VERSION};
  }

  public deleteLink(link : Link){
    const index = this._links.indexOf(link);
    this._links.splice(index,1);
    this._linkDeleteListener.next(link);
    if (this._linkActive == link) this.linkSetFirst();
  }


  get loadedDashboard(): Dashboard { return this._loadedDashboard; }
}
